<template>
  <main>
    <div>
      <div class="md:wrapper text-wrap w-11/12 flex flex-col gap-4 md:mx-auto">
        <div style="text-align: center; padding-top: 20px" class="">
          <div style="margin-top: auto; margin-bottom: auto">
            <p style="text-align: center; font-size: 18px">
              Terms & Conditions
            </p>
          </div>

          <div
            style="
              margin-top: 10px;
              background-color: #f3f3f3;
              padding: 10px 10px;
              border-radius: 16px;
            "
            class="flex justify-center"
          >
            <img
              class="logo-class"
              src="http://eazipay.storage.googleapis.com/21144296-e6c4-48d6-aa1c-2ffd2b627ca4-eazi-logo.png"
              alt=""
            />
          </div>
        </div>
        <div style="margin-left: 20px">
          <p class="text-left" style="line-height: 32px">
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. THEY ARE A LEGAL
            AGREEMENT BETWEEN MYEAZIPAY TECHNOLOGY SERVICES LIMITED AND YOU. BY
            CLICKING "I ACCEPT" OR USING OUR SERVICES, YOU AGREE TO THESE TERMS.
            IF YOU DON'T AGREE, PLEASE DO NOT USE OUR SERVICES.
          </p>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            General Provisions
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              These Terms and Conditions constitute the entire agreement between
              the parties, overriding any prior agreements or representations.
              In case of interpretation ambiguity, the spirit of the provisions
              will guide, and the company can reject platform use or
              registration without stating reasons, with notification provided
              within 10 working days.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Definitions
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              <strong>"Company"</strong> or <strong>"we"</strong> or
              <strong>“Eazipay”</strong> shall mean
              <strong>MYEAZIPAY TECHNOLOGY SERVICES LIMITED</strong>, a company
              incorporated under the Laws of the Federal Republic of Nigeria
              with its registered office in Lagos, Nigeria.
            </p>
            <p>
              <strong>"Customers(s)"</strong> or <strong>"you"</strong> shall
              mean any person who accesses, downloads, uses, and views the
              Platform and the Services rendered on it.
            </p>
            <p>
              <strong>"Online Stores"</strong> shall mean Windows Store, Android
              Google Play, iOS App store or any other online store or portal
              where the Platform will be made available by the Company to the
              Customer, from time to time.
            </p>
            <p>
              <strong>"Platform</strong> shall mean the App and the Website
              collectively.
            </p>
            <p>
              <strong>"Services"</strong> shall mean the services of payroll
              processing and payments and bookkeeping services, including
              receipts of payment and disbursal as requested or initiated by the
              customer on the Platform.
            </p>
            <p>
              <strong>“Third Party”</strong> shall mean any association,
              partnership, firm, company, corporation, consultant,
              subcontractor, or combination thereof, including joint ventures,
              that is not a party to this Agreement. 
            </p>

            <p>
              <strong>"Website"</strong> shall mean
              <span
                style="
                  color: #2f80ed;
                  font-weight: 400;
                  text-decoration: underline;
                "
              >
                <a href="https://www.myeazipay.com">www.myeazipay.com,</a></span
              >
              managed and operated by the Company for the provision of Services.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Use of the Platform
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              To use the Platform, you must agree to these Terms and our
              <span
                style="
                  color: #2f80ed;
                  font-weight: 400;
                  text-decoration: underline;
                "
                ><a href="https://www.myeazipay.com/privacy-policy"
                  >Privacy Notice,</a
                ></span
              >
              be at least 13 years old, register on the website, have a bank
              account, and provide accurate user information. Once approved,
              you'll receive login details via email.
            </p>
          </div>

          <div style="margin-left: 20px; margin-top: 40px">
            <p>
              You must provide truthful and up-to-date user information, and you
              agree not to misrepresent your identity. If approved, you'll get
              login details to access the platform using a verified email and
              password. We may monitor your usage, and if it affects the
              platform's integrity, security, or performance, we can restrict
              your access without notice. We may also suspend access for
              maintenance, and we'll try to notify you in advance for
              non-emergency maintenance outside business hours.
            </p>

            <p>
              Our Services <br />
              Our services enable quick payments upon registration. We collect
              and update your details as needed and operate in accordance with
              data protection laws. You're responsible for transactions based on
              your instructions. We may grant access to our services based on
              your eligibility and our terms. You're responsible for your
              account activities, and we may accept or reject your registration.
            </p>
            <p>
              Additional Service <br />
              By accepting these Terms, you agree that the Company may introduce
              you to Third Parties for additional services.
            </p>

            <p>
              Fees and Billings <br />
              You must pay a percentage unless agreed upon otherwise, and the
              company can change the fees with a one-week notice on the platform
              or via email.
            </p>

            <p>
              Confidentiality And Data Security<br />
              You own and are responsible for the accuracy of your User
              Information on the platform, and we store it for your convenience
              without using it for other purposes. You're still responsible for
              outstanding payments even if you delete the platform.  You agree
              to ensure your User Information doesn't violate third-party rights
              and hold the company harmless from any third-party claims related
              to your data or the agreement. When you subscribe to the platform,
              you consent to our processing of your User Information. 
            </p>

            <p>
              License<br />
              You can download the app or use the website directly to use our
              services on our platform. The Company grants you a limited,
              non-transferable right to use the platform per these terms. You
              can use it on various electronic devices. While the company may
              provide maintenance and support at its discretion, it's not
              obligated to do so. Updates and upgrades are available; if you
              choose not to update, some features may not be accessible.
            </p>

            <p>
              Restrictions<br />
              You agree not to use the platform for illegal, unlawful or harmful
              purposes, share objectionable content, impersonate others,
              infringe on intellectual property rights, send spam, or disrupt
              the platform and its users.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Proprietary Properties Of The Company
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              The company owns all rights, including intellectual property, in
              the platform, services, logos, and software, except for
              third-party properties. The platform belongs to the company; these
              terms don't transfer ownership to you.
            </p>
            <p>
              Password and Account Security <br />
              Do not share your password, and Eazipay is not liable for losses
              if you do. Secure your device with a PIN or password, and report
              unauthorised account access to us.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Links To Third Parties
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              The platform may have links to third-party websites that the
              company doesn't control or endorse. Your use of these links is at
              your own risk, subject to their terms, and the company isn't
              involved in transactions with them.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Refund
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              Refund requests should be made through the platform, and approval
              may take up to 3 business days. Refunds will be credited to the
              linked bank card.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Termination
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              The company may terminate these terms if you violate them, if
              doing so is necessary by law, during liquidation, when ceasing to
              operate the service or platform, when the license expires, or as a
              result of non-payment. Termination doesn't exempt you from
              fulfilling your obligations, including repaying outstanding
              amounts.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Disclaimer Of Warranties
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              Your use of the platform and services is at your own risk, and
              they are provided "as is." The company disclaims all warranties,
              and any material you download is at your discretion and risk. The
              company is not responsible for any damages not explicitly
              mentioned in these terms and makes no guarantees about
              completeness, accuracy, or uninterrupted service.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Indemnity
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              You agree to protect the company from third-party claims related
              to your violation of these terms, infringing on other users'
              rights, or breaking the law while using the platform. 
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Limitations of Liability
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              The company and its representatives are not liable for any direct
              or indirect damages, including loss of profits, resulting from the
              use or inability to use the platform, performance or
              non-performance by the company, or any issues related to the
              platform, and you are solely responsible for activities on your
              user account while using the platform.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Force Majeure
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              If we do not meet our obligations under these terms because of
              things we could not have predicted, like strikes, natural
              disasters, government actions, or other things we could not have
              planned for, we will not be responsible. During these times, we
              will get more time to meet our obligations.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Notice
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              All notices given by you to us must be given in writing (including
              by email) to hi@myeazipay.com as may be updated occasionally.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Changes to this Terms & Conditions
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              The company can change the terms at any time by posting updates on
              the platform, and your continued use implies acceptance of the
              revised terms.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Violations
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              Please report any violations or grievances in relation to these
              Terms and Conditions to the Company at hi@myeazipay.com.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Site Management
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              We have the right, but not the obligation, to monitor the site for
              violations of these terms, take legal action against violators,
              restrict access to or remove contributions that are too large or
              burdensome, and manage the site to protect our rights and ensure
              its proper functioning.
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Waiver
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p>
              Our failure to enforce any of your obligations or rights under
              these terms doesn't mean we waive those rights or excuse you from
              following them. Any waiver of our rights must be explicitly stated
              in writing to be valid.
            </p>
          </div>
        </div>

        <!-- <div style="margin-top: 20px">
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Indemnity for Cumulative Debit Transaction Increase
          </div>
          <div style="margin-left: 20px" class="section-content">
            <p style="line-height: 32px">
              THIS INDEMNITY is given on the ________ day of _________, whose
              address is  at
              _______________________________________________________________
              with identity number ___________________________ (passport number,
              NIN, Permanent Voters Card (PVC), Driver’s Licence) (hereinafter
              referred to as the “Customer” or “Indemnitor” which expression
              shall where the context so admits include his/her heirs,
              legal/personal representatives, executors/administrators and
              assigns) in favour of MyEazipay Technology Services Limited, a
              company incorporated in Nigeria and whose registered address is at
              ___________________________________________________________
              [address] Lagos State (hereinafter referred to as the "Company"
              which expression shall where the context so admits include its
              successors in title and assigns).
            </p>
          </div>
        </div>

        <div>
          <div
            style="margin-left: 20px"
            class="section-title text-left font-bold"
          >
            Background:
          </div>
          <div
            style="margin-left: 20px"
            class="list-decimal nested-list section-content"
          >
            <ol>
              <li>
                The Customer maintains an account with the Company (my/our
                “Account”).
              </li>
              <li>
                The Company has advised and provided that the daily limit, for
                daily cumulative debit fund transfers for individual accounts in
                different tiers, shall be as follows:
              </li>
              <li>
                Tier 1: N100,000 (One Hundred Thousand Naira) for individual
                accounts.
              </li>
              <li>
                Tier 2: N5,000,000 (Five Million Naira) for individual accounts.
              </li>
              <li>
                Tier 3: >N5,000,000 (Over Five Million Naira) for
                individual/corporate accounts.
              </li>
              <li>
                At my/our request and in compliance with the criteria set for
                Tier 1/Tier 2/Tier 3, the Company has approved the allocation of
                privileges to my/our account to streamline and facilitate
                transactions on the said account.
              </li>
              <li>
                That I/we have requested that the daily limit for my/our debit
                transactions funds transfer from my/our account be increased to
                [Specify the requested limit].
              </li>
              <li>
                The Company has requested an indemnity as a condition for the
                grant of the Indemnitor's request owing to the inherent risk of
                making higher sum payments on electronic instant payment
                platforms, and the Indemnitor has agreed to execute this
                Indemnity in favor of the Company together with its payment and
                banking partners (the "Indemnified Parties") to fully indemnify
                the Indemnified Parties against any liability, loss, costs or
                damages that may be incurred or suffered by the Indemnified
                parties as a result of granting the Indemnitor's request.
              </li>
            </ol>

            <p style="line-height: 36px">
              NOW IN CONSIDERATION of the above, the Indemnitor hereby
              undertakes to and accordingly indemnifies and keep fully
              indemnified the Indemnified Parties against all damages, injuries,
              losses, costs and expenses whatsoever which may be incurred,
              suffered or sustained by any of them as well as against all
              actions, proceedings or claims which may be brought against any of
              the Indemnified Party in consequence of the Company granting the
              Indemnitor's request to increase their daily electronic instant
              payment limit. <br />
              <span style="margin-top: 10px"
                >This Indemnity shall be a continuing and binding obligation on
                the Indemnitor until the Company costs, expenses, or liabilities
                in shall have effectively discharged the Indemnitor from any
                claims, respect thereof.</span
              >
              <br />
              <span style="margin-top: 10px"
                >This Indemnity shall be construed in accordance with the Laws
                of the Federal Republic of Nigeria.</span
              >
            </p>
          </div>
        </div>
        <p style="margin-left: 20px; margin-top: -5px">
          Dated this _____ day of _____________ 2023.
        </p>
        <div
          style="margin-top: 0px; margin-left: -24px"
          class="signature-container"
        >
          <div class="signature-field left">
            <p style="margin-bottom: 20px">By the within named Customer:</p>
            <p>.............................................</p>
            <p>Name</p>
          </div>

          <div style="margin-top: 85px" class="signature-field right">
            <label>Signature 2:</label> 
            <p>.............................................</p>
            <p>Signature</p>
          </div>
        </div>

        <div style="margin-left: 20px; margin-top: 90px">
          <p>In the Presence of</p>
          <p>
            Name: ............................................................
          </p>

          <p>
            Occupation:
            ...................................................................
          </p>
          <p>
            Address:
            ...................................................................
          </p>
          <p>
            Signature:
            ...................................................................
          </p>
        </div> -->

        <p style="font-weight: 500; margin-left: 20px">
          <strong
            >Kindly signify your acceptance of this offer by checking the Terms
            and Conditions box on the application page of the Eazipay
            app.</strong
          >
          <br />
        </p>

        <!-- <div style="display: flex; gap: 10px; margin-left: 20px">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_25419_2271)">
              <path
                d="M13.6448 24C12.5511 24 11.4574 24 10.3637 24C10.1618 23.9859 9.95997 23.9624 9.75812 23.9531C8.30764 23.8873 6.86656 23.7512 5.44894 23.3896C3.00801 22.7699 1.38855 21.3004 0.698519 18.8449C0.280745 17.3613 0.12584 15.8448 0.0554283 14.3142C-0.0290654 12.4972 -0.0196772 10.6756 0.102369 8.85859C0.191557 7.59563 0.35585 6.34676 0.707907 5.12136C1.37916 2.81611 2.8625 1.35126 5.17199 0.693961C6.64124 0.2808 8.14335 0.125864 9.65485 0.0554392C11.4902 -0.0290711 13.3209 -0.019681 15.1563 0.102389C16.3017 0.177509 17.4377 0.332445 18.5549 0.614146C21.0709 1.24797 22.6857 2.79263 23.3522 5.31855C23.662 6.4923 23.8169 7.68953 23.9061 8.89615C23.939 9.38443 23.9718 9.8774 24 10.3657C24 11.4596 24 12.5536 24 13.6475C23.9859 13.8494 23.9624 14.0513 23.9531 14.2532C23.8873 15.7039 23.7465 17.1453 23.3898 18.5632C22.7514 21.0797 21.2117 22.6948 18.691 23.3615C17.5175 23.6713 16.3205 23.8263 15.1141 23.9155C14.6212 23.9437 14.133 23.9718 13.6448 24ZM12.0113 1.98978C11.1288 2.01795 10.2463 2.02265 9.36851 2.07429C8.08233 2.14472 6.80553 2.29026 5.5616 2.66587C4.14868 3.09781 3.18639 3.98047 2.72167 5.40306C2.28982 6.71297 2.1443 8.06982 2.07389 9.43607C1.97531 11.3704 1.97531 13.3095 2.11614 15.2438C2.19593 16.333 2.34615 17.4129 2.67473 18.4646C3.0972 19.8308 3.94214 20.7792 5.31281 21.2534C6.65063 21.7135 8.03539 21.8638 9.42953 21.9342C11.3635 22.0375 13.3022 22.0328 15.2361 21.8919C16.3345 21.8121 17.4236 21.6619 18.4797 21.3285C19.8457 20.9013 20.7892 20.0515 21.2586 18.6759C21.6436 17.5491 21.8032 16.3847 21.8924 15.2109C22.0332 13.2907 22.0332 11.3704 21.9346 9.45016C21.8689 8.13086 21.7234 6.82095 21.3384 5.5486C20.9019 4.12132 20.0147 3.14006 18.5736 2.71282C17.9118 2.51563 17.2311 2.36069 16.5458 2.2574C15.0437 2.02734 13.5275 2.00387 12.0113 1.98978Z"
                fill="#11453B"
              />
              <rect
                x="1.33398"
                y="1.3335"
                width="21.3333"
                height="21.3333"
                rx="8"
                fill="#11453B"
              />
              <path
                d="M11.0504 12.6294C11.1067 12.5448 11.1443 12.4509 11.21 12.3852C12.5527 11.0378 13.9001 9.69038 15.2475 8.34297C15.6748 7.91574 16.2851 7.89696 16.6936 8.28663C17.102 8.681 17.102 9.30541 16.6842 9.73264C16.1771 10.2491 15.6607 10.7608 15.1443 11.2725C14.0269 12.3899 12.9142 13.5026 11.7968 14.62C11.2804 15.1364 10.717 15.1364 10.2006 14.62C9.40717 13.8265 8.60904 13.0331 7.81562 12.235C7.48228 11.897 7.40717 11.4697 7.59496 11.0754C7.76867 10.7092 8.15834 10.4791 8.56679 10.5073C8.84379 10.5261 9.06914 10.6575 9.26163 10.8547C9.77806 11.3758 10.2992 11.8923 10.8156 12.4134C10.8766 12.465 10.933 12.5167 11.0504 12.6294Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_25419_2271">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p style="margin-top: 1px">
            <strong
              >I have read and agreed to Eazipay
              <span style="color: #2f80ed; font-weight: 400"
                ><a href="https://www.myeazipay.com/privacy-policy"
                  >Terms & Privacy Policy.</a
                ></span
              >
            </strong>
          </p>
        </div> -->
      </div>
    </div>
  </main>
</template>

<style scoped>
.wrapper {
  width: 80%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 40px;
  overflow-wrap: break-word;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fullPage {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  page-break-after: always;
  /* text-align: justify; */
}

p {
  font-size: 18px;
  color: #474747;
  line-height: 32px;
}
h1 {
  color: #474747;
  text-align: left;
  margin-bottom: 20px;
}
.text-left {
  text-align: left;
}
.font-bold {
  font-weight: 700;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.list-decimal {
  list-style-type: decimal;
}

.section {
  margin-bottom: 30px;
}

.section-title {
  font-weight: bold;
  font-size: 18px;
}

.subsection {
  margin-left: 20px;
  margin-bottom: 20px;
}

.subsection-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}

.subsection-content {
  margin-left: 20px;
}

.section-content {
  /* margin-left: 20px; */
  text-align: left;
}

/* Custom CSS for Roman numerals */
.roman-list {
  list-style-type: none;
  counter-reset: roman-counter;
  margin-left: 1em;
}

.roman-list li {
  position: relative;
  padding-left: 1em; /* Adjust the spacing as needed */
}

.roman-list li:before {
  content: counter(roman-counter, lower-roman) ".";
  position: absolute;
  left: -1em; /* Adjust the spacing as needed */
  width: 1.5em; /* Adjust the width as needed */
  text-align: right;
  margin-right: 0.5em;
  counter-increment: roman-counter;
}

.alphabet-list {
  list-style-type: none;
  counter-reset: alphabet-counter;
  margin-left: 1em;
}

.alphabet-list li {
  position: relative;
  padding-left: 1em;
}

.alphabet-list li:before {
  content: "(" counter(alphabet-counter, lower-alpha) ")";
  position: absolute;
  left: -1em; /* Adjust the spacing as needed */
  width: 1.5em; /* Adjust the width as needed */
  text-align: right;
  margin-right: 0.5em;
  counter-increment: alphabet-counter;
}

.nested-list {
  list-style-type: none;
  counter-reset: parent-counter;
}

.nested-list > li {
  counter-increment: parent-counter;
}

.nested-list > li:before {
  content: counter(parent-counter) ".";
  margin-right: 0.5em;
}

.nested-list .sub-list {
  list-style-type: none;
  counter-reset: sub-counter;
  margin: 1em 0 0 0;
}

.nested-list .sub-list > li:before {
  content: counter(parent-counter) "." counter(sub-counter);
  counter-increment: sub-counter;
  margin-right: 0.5em;
}
li {
  line-height: 1.5;
  font-size: 14px;
  color: #474747;
}
.top-right {
  position: absolute;
  top: -20;
  right: -20;
  padding: 20px;
  /* background-color: #f1f1f1; */
}
.signature-container {
  position: relative;
  width: 100%;
  height: 130px;
}

.signature-field {
  position: absolute;
  width: 200px;
  padding-bottom: 5px;
}

.signature-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.signature-field input {
  border: none;
  background: none;
  margin-top: 30px;
  border-bottom: 1px solid black;
  font-size: 16px;
  width: 100%;
  padding: 5px;
}

.signature-field.left {
  top: 40px;
  left: 45px;
}

.signature-field.right {
  top: 40px;
  right: 340px;
}

.table {
  overflow: hidden;
}

table {
  /* background-color: rgba(255, 255, 255, 0.8); */
  margin: 50px auto;
  width: 100%;
  border-spacing: 0;
  border: 1px solid #d8d8d8;
  border-radius: 17px;

  font-family: sans-serif;
  color: #474747;
  overflow: hidden;
}

th {
  background-color: #e9e9e9;
}

td,
th {
  border: 1px solid #d8d8d8;
  text-align: left;
  padding: 20px;
}

a:any-link {
  text-decoration: none;
  color: #2f80ed;
}
.logo-class {
  width: 60px;
}
@media (max-width: 768px) {
  html,
  body {
    width: 100vw !important;
    height: 100vh;
    margin: 0;
  }

  .logo-class {
    width: 100px;
  }

  .wrapper {
    width: 1600px;
    margin: 0;
    padding-bottom: 40px;
    position: relative;
  }

  /* Styles specific to mobile devices */
}
</style>
