<template>
  <easiLayout>
    <div
      v-if="showsplash"
      class="w-screen fixed inset-0 lg:hidden z-20 overflow-hidden"
      :style="{
        backgroundImage: ' url(' + mobilebackground + ')',
      }"
      style="
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 102vh;
      "
    >
      <div
        class="pt-10 flex flex-col items-center absolute w-full bottom-20 left-1/2 transform -translate-x-1/2"
      >
        <p class="font-normal text-5xl text-mint font-made-mellow">
          More than Payroll
        </p>
        <p class="text-lg text-white">Grow. Thrive. Prosper. With Eazipay</p>
        <div class="flex flex-col justify-center items-center gap-4 my-10">
          <easiButton
            variant="outlined"
            class="bg-white border-none"
            @click="showsplash = false"
          >
            <span class="text-sm text-dark-800">
              <span class="text-sm px-4"> Get Started </span>
            </span>
          </easiButton>

          <router-link class="px-6 bg-transparent mt-3 text-white" to="/login"
            >Sign in</router-link
          >
        </div>
      </div>
    </div>
    <div
      class="py-10 h-full min-h-screen relative flex items-start sm:items-center justify-center"
    >
      <!-- ///////// Select Account Type Start Here /////// -->
      <div
        v-if="display === 'account-type'"
        class="w-11/12 sm:w-10/12 md:w-10/12 flex flex-col items-center justify-center"
      >
        <easiContainer
          bg="bg-white"
          :isClose="false"
          @close="$router.go(-1)"
          class="w-full sm:py-10 rounded-80px md:border border-outlineGray"
          paddingHorizontal=" "
        >
          <div class="w-full space-y-6 flex flex-col items-center">
            <!-- /////Personal Card //////-->

            <img
              class="w-16 h-16 mb-4"
              src="@/assets/img/signupLogo.svg"
              alt=""
            />
            <div class="text-primary-hover text-3xl font-bold">
              Welcome to Eazipay
            </div>
            <div class="pb-4 mx-4">
              Please select an account type to continue
            </div>
            <div
              @click="selectregistrationType('PERSONAL')"
              :class="
                args.registrationType === 'PERSONAL'
                  ? 'border-primary bg-newLimeGreen shadow-md'
                  : 'bg-white'
              "
              class="border overflow-hidden rounded-23px md:rounded-25px w-11/12 sm:w-10/12 md:w-8/12 relative cursor-pointer"
            >
              <span class="absolute top-3 right-3 cursor-pointer">
                <img
                  v-if="args.registrationType === 'PERSONAL'"
                  src="../../assets/icons/check-active.svg"
                  alt=""
                />
              </span>
              <div class="flex justify-between z-10">
                <div></div>
                <div
                  class="w-2/3 text-left z-10 absolute top-1/2 transform -translate-y-1/2 left-6"
                >
                  <h5 class="font-bold my-1 text-primary text-sm sm:text-base">
                    Personal Account
                  </h5>
                  <p class="font-normal text-xs md:text-sm text-dark-800">
                    For individuals who pay their personal and 
                    domestic staff
                  </p>
                </div>
                <img class="w-1/3" src="@/assets/img/personalCard.svg" alt="" />
              </div>
            </div>
            <!-- ///// Business Card //////-->
            <div
              @click="selectregistrationType('COMPANY')"
              :class="
                args.registrationType === 'COMPANY'
                  ? 'border-primary bg-newLimeGreen shadow-md'
                  : 'bg-white'
              "
              class="border overflow-hidden rounded-23px md:rounded-25px w-11/12 sm:w-10/12 md:w-8/12 relative cursor-pointer"
            >
              <span class="absolute top-3 right-3 cursor-pointer">
                <img
                  v-if="args.registrationType === 'COMPANY'"
                  src="../../assets/icons/check-active.svg"
                  alt=""
                />
              </span>
              <div class="flex justify-between z-10">
                <div></div>
                <div
                  class="w-2/3 text-left z-10 absolute top-1/2 transform -translate-y-1/2 left-6"
                >
                  <h5 class="font-bold my-1 text-primary text-sm sm:text-base">
                    Business Account
                  </h5>
                  <p class="font-normal text-xs md:text-sm text-dark-800">
                    For SMEs, startups, 
                    corporates, and large companies.
                  </p>
                </div>
                <img class="w-1/3" src="@/assets/img/businessCard.svg" alt="" />
              </div>
            </div>

            <!-- //// button ///// -->
          </div>
          <div class="px-3 sm:px-12 md:px-20 mt-10">
            <easiButton
              block
              rounded="3xl"
              :disabled="!args.registrationType"
              @click="args.registrationType ? (display = 'account-form') : null"
              color=" newGreen"
              >Proceed</easiButton
            >
          </div>
          <div class="text-dark-800 mt-10 text-base font-medium text-center">
            Already have an account?
            <router-link class="text-secondary" to="/login"
              >Sign in</router-link
            >
          </div>
        </easiContainer>
      </div>

      <!-- ///////// Select Account Type End Here /////// -->

      <!-- ///////// Account Form Start Here /////// -->
      <div
        v-if="display === 'account-form'"
        class="w-10/12 md:w-10/12 flex flex-col items-center justify-center"
      >
        <div class="flex justify-center">
          <img
            class="w-16 h-16 mb-10 rounded-xl"
            src="@/assets/img/signupLogo.svg"
            alt=""
          />
        </div>
        <easiContainer
          bg="bg-white "
          :isClose="false"
          class="w-full bg-opacity-50 px-4 sm:px-6 md:px-8 md:px-30 h-screen-70"
          paddingHorizontal=" "
          @close="
            showPasscode ? (showPasscode = false) : (display = 'account-type')
          "
        >
          <form class="" @submit.prevent="" ref="form" autocomplete="on">
            <div>
              <easiBlinkingTextInput
                v-if="currentInput === 0"
                label="Enter your email address"
                v-model="args.email"
                :error="errorRules.email"
                :loading="submittingEmail"
                type="email"
                @next="
                  () => {
                    if (!validateEmail(args.email.trim())) {
                      errorRules.email = 'Please enter a valid email address';
                    } else {
                      submitEmail();
                    }
                  }
                "
                @back="
                  showPasscode
                    ? (showPasscode = false)
                    : (display = 'account-type')
                "
              />
              <easiBlinkingTextInput
                v-if="currentInput === 1"
                label="Enter your first name"
                v-model="args.firstName"
                :error="errorRules.firstName"
                @next="currentInput++"
                :disabled="!args.firstName.trim()"
                @back="currentInput--"
              />
              <easiBlinkingTextInput
                v-if="currentInput === 2"
                label="Enter your last name"
                v-model="args.lastName"
                :error="errorRules.lastName"
                :disabled="!args.lastName.trim()"
                @next="currentInput++"
                @back="currentInput--"
              />

              <easiBlinkingTextInput
                v-if="currentInput === 3"
                label="Enter your
              phone number"
                v-model="args.phoneNumber"
                :error="errorRules.phoneNumber"
                type="number"
                @next="
                  () => {
                    if (args.phoneNumber.trim() === '') {
                      errorRules.phoneNumber =
                        'Please fill in your phone number';
                      return false;
                    } else if (!validatePhone(args.phoneNumber.trim())) {
                      errorRules.phoneNumber =
                        'Please fill in a valid phone number';
                    } else if (
                      isNaN(args.phoneNumber.trim()) ||
                      processNumber(args.phoneNumber).length > 13
                    ) {
                      errorRules.phoneNumber =
                        'Please fill in a valid phone number';
                      return false;
                    } else {
                      if (args.registrationType === 'COMPANY') {
                        currentInput++;
                      } else {
                        currentInput += 2;
                      }
                    }
                  }
                "
                @back="currentInput--"
              />
              <easiBlinkingTextInput
                v-if="currentInput === 4"
                label="What is the name of your Company?"
                v-model="args.companyName"
                :error="errorRules.companyName"
                @next="currentInput++"
                @back="currentInput--"
                :disabled="!args.companyName.trim()"
              />

              <easiBlinkingTextInput
                v-if="currentInput === 5"
                optional
                showSlotButton
                label="Please enter a referral code if you were referred (optional)."
                v-model="args.referralCode"
                :error="errorRules.referralCode"
                @next="
                  () => {
                    if (!indemCheck) return;
                    if (args.registrationType === 'COMPANY') {
                      currentInput = 11;
                    } else {
                      currentInput++;
                    }
                  }
                "
                @back="
                  () => {
                    if (args.registrationType === 'COMPANY') {
                      currentInput--;
                    } else {
                      currentInput -= 2;
                    }
                  }
                "
                buttonLabel="Submit form"
                :disabled="!indemCheck"
              >
                <div
                  class="cursor-pointer flex items-center gap-4 mt-5 text-sm"
                >
                  <!-- <img
                    v-show="indemCheck"
                    class="w-6 h-6"
                    src="@/assets/icons/i-checked.svg"
                    alt=""
                  />
                  <img
                    v-show="!indemCheck"
                    class="w-6 h-6"
                    src="@/assets/icons/i-checked-inactive.svg"
                    alt=""
                  /> -->
                  <input
                    class="w-6 h-6 rounded-md"
                    type="checkbox"
                    name="checko"
                    id=""
                    :checked="indemCheck"
                    :value="false"
                    @change="indemCheck = !indemCheck"
                  />
                  <label for="checko" style="margin-top: 1px">
                    I have read and agreed to Eazipay
                    <span
                      @click="preview = !preview"
                      style="color: #2f80ed; font-weight: 400"
                    >
                      Terms & Privacy Policy.</span
                    >
                  </label>
                </div>
              </easiBlinkingTextInput>
              <easiBlinkingTextInput
                v-if="currentInput === 6"
                noInput
                showSlotButton
                label="Create your Passcode"
                :loading="loading.btnGetstarted"
                @next="submit"
                @back="
                  () => {
                    if (accountType === 'COMPANY') {
                      currentInput = 13;
                    } else {
                      currentInput--;
                    }
                  }
                "
                buttonLabel="Create Password"
                :disabled="!args.confirmPin"
                optional
              >
                <div class="mt-10 space-y-10">
                  <div>
                    <div class="flex justify-start items-center mb-3">
                      <span> Enter Passcode </span>
                      <img
                        v-if="showPin"
                        @click="showPin = !showPin"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-close.svg"
                      />
                      <img
                        v-else
                        @click="showPin = !showPin"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-open.svg"
                      />
                    </div>

                    <span class="mb-4 w-fit flex justify-around items-center">
                      <easiOtp
                        class="w-full sm:w-auto"
                        :type="showPin ? 'password' : 'number'"
                        size="10"
                        :token_length="6"
                        :max-length="1"
                        @input="checkPin"
                        :error="errorRules.pin"
                      />
                    </span>
                  </div>
                  <div>
                    <div class="flex justify-start items-center mb-3">
                      <span class="capitalize text-newGray text-sm font-bold"
                        >confirm passcode
                      </span>

                      <img
                        v-if="showPin1"
                        @click="showPin1 = !showPin1"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-close.svg"
                      />
                      <img
                        v-else
                        @click="showPin1 = !showPin1"
                        class="cursor-pointer ml-2"
                        src="../../assets/icons/eye-open.svg"
                      />
                    </div>

                    <span class="mb-4 flex justify-center items-center">
                      <easiOtp
                        class=""
                        :type="showPin1 ? 'password' : 'number'"
                        size="10"
                        :token_length="6"
                        :max-length="1"
                        @input="checkConfirmPin"
                        :error="errorRules.confirmPin"
                      />
                    </span>
                  </div>
                </div>
              </easiBlinkingTextInput>
              <easiBlinkingTextInput
                v-if="currentInput === 7"
                noInput
                label=""
                :loading="loading.confirm"
                @next="confirmRegistration"
                @back="currentInput--"
                buttonLabel="Verify"
                :disabled="otpCode.length < 6"
                optional
              >
                <div class="mb-3">
                  <p class="text-2xl font-bold text-grey mb-0">
                    OTP Verification
                  </p>

                  <span class="text-base md:text-md mt-1 font-medium block">
                    Please input OTP sent to your email and phone number
                  </span>
                </div>
                <span class="mb-4 flex justify-around items-center">
                  <easiOtp
                    v-model="otpCode"
                    size="10"
                    :token_length="6"
                    :max-length="1"
                    @input="checkOTP"
                    :error="errorRules.otpCode"
                  />
                </span>
                <div
                  class="text-dark-800 font-bold text-sm text-left flex items-center justify-start"
                >
                  Did not receive OTP?
                  <easiButton
                    @click="resendOTP"
                    variant="text"
                    :loading="loading.btnOtp"
                    color="secondary"
                    class="text-sm px-0"
                    type="button"
                    >Resend</easiButton
                  >
                </div>
              </easiBlinkingTextInput>
              <easiBlinkingTextInput
                v-if="currentInput === 11"
                noInput
                noButton
                label="Are you a registered business"
                :loading="loading.confirm"
                @next="confirmRegistration"
                @back="currentInput = 5"
              >
                <div class="flex gap-3">
                  <div
                    class="border px-6 py-3 rounded-4xl cursor-pointer"
                    :class="
                      isRegistered === false
                        ? ' border-primary bg-promoGreen'
                        : '  border-outlineGray bg-white'
                    "
                    @click="setIsRegistered(false)"
                  >
                    No
                  </div>
                  <div
                    class="border border-outlineGray px-6 py-3 rounded-4xl cursor-pointer"
                    :class="
                      isRegistered
                        ? ' border-primary bg-promoGreen'
                        : '  border-outlineGray bg-white'
                    "
                    @click="setIsRegistered(true)"
                  >
                    Yes
                  </div>
                </div>
              </easiBlinkingTextInput>
              <easiBlinkingTextInput
                v-if="currentInput === 12"
                noInput
                label="Kindly select your business type"
                :loading="loading.confirm"
                @back="currentInput--"
                noButton
              >
                <div class="flex flex-col gap-6">
                  <div
                    v-for="(option, optionIndex) in businessTypes"
                    :key="optionIndex"
                    class="flex items-center cursor-pointer gap-2"
                    @click="handleBusinessType(option.value)"
                  >
                    <div
                      class="flex items-center justify-center border w-6 h-6 rounded-full"
                      :class="
                        businessType === option.value
                          ? 'border-primary'
                          : 'border-newLightGray'
                      "
                    >
                      <div
                        v-if="businessType === option.value"
                        class="flex items-center justify-center w-4 h-4 bg-primary rounded-full"
                      ></div>
                    </div>
                    <div class="text-grey">{{ option.name }}</div>
                  </div>
                </div>
              </easiBlinkingTextInput>
              <easiBlinkingTextInput
                v-if="currentInput === 13"
                optional
                label="Kindly select your role in the Company"
                :loading="loading.confirm"
                @next="currentInput = 6"
                @back="currentInput--"
                :noInput="!companyRoleOthers"
                :noButton="!companyRoleOthers"
                :showSlotButton="companyRoleOthers"
                v-model="companyRole"
              >
                <template #customSlot>
                  <div class="flex flex-col gap-6 mb-6">
                    <div
                      v-for="(option, optionIndex) in roles"
                      :key="optionIndex"
                      class="flex items-center cursor-pointer gap-2"
                      @click="handleBusinessRole(option.value)"
                    >
                      <div
                        class="flex items-center justify-center border w-6 h-6 rounded-full"
                        :class="
                          companyRole === option.value
                            ? 'border-primary'
                            : 'border-newLightGray'
                        "
                      >
                        <div
                          v-if="companyRole === option.value"
                          class="flex items-center justify-center w-4 h-4 bg-primary rounded-full"
                        ></div>
                      </div>
                      <div class="text-grey">{{ option.name }}</div>
                    </div>
                    <div
                      class="flex items-center cursor-pointer gap-2"
                      @click="
                        () => {
                          companyRole = '';
                          companyRoleOthers = !companyRoleOthers;
                        }
                      "
                    >
                      <div
                        class="flex items-center justify-center border w-6 h-6 rounded-full"
                        :class="
                          companyRoleOthers
                            ? 'border-primary'
                            : 'border-newLightGray'
                        "
                      >
                        <div
                          v-if="companyRoleOthers"
                          class="flex items-center justify-center w-4 h-4 bg-primary rounded-full"
                        ></div>
                      </div>
                      <div class="text-grey">Others</div>
                    </div>
                  </div>
                </template>
              </easiBlinkingTextInput>
            </div>
          </form>
        </easiContainer>
      </div>

      <easiAlert
        v-if="successModal"
        @close="successModal = false"
        noIcon
        :showDefaultTop="false"
      >
        <div class="px-6">
          <div class="flex justify-center pt-6">
            <span>
              <img src="@/assets/img/newSuccess.gif" class="h-32" alt="" />
            </span>
            <!-- <img class="w-32" :src="success" alt="" /> -->
          </div>
          <div class="w-10/12 mx-auto text-center font-bold text-lg mt-6">
            Congratulations Your Eazipay
            {{ accountType === "COMPANY" ? "Company" : "Individual" }} account
            has been created successfully.
          </div>

          <main class="w-full mt-6 space-y-4">
            <easiButton @click="$router.push('admin/dashboard')" block
              >Proceed to Dashboard
            </easiButton>
          </main>
        </div>
      </easiAlert>
    </div>

    <easiModal
      :terms="true"
      :isBorder="false"
      rounded="2xl"
      v-show="preview"
      @close="preview = false"
    >
      <div
        class="w-full flex flex-col items-center h-auto overflow-y-scoll"
        style="overflow: auto; height: calc(100vh - 250px)"
      >
        <!-- <iframe
          src="https://eazipay.storage.googleapis.com/e25cbd6d-abe4-4caa-8b57-a43b64b3c5a3-indemnity-form-(2).pdf"
          style="width: 98%; height: 100%"
        >
        </iframe> -->
        <!-- <iframe :srcdoc="htmlContent" style="width: 98%; height: 100%">
        </iframe> -->
        <div class="w-full overflow-x-hidden">
          <htmlContent />
        </div>
      </div>
    </easiModal>
  </easiLayout>

  <!-- //////// Main form here ///////// -->
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";
import { ref, reactive, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "../../composable/helperFunctions";
import htmlContent from "@/views/main/Indemnity.vue";
import mobilebackground from "@/assets/img/signupMobile.svg";

const { processNumber, log, validateEmail, validatePhone } = helperFunctions;
const { mutate, query, set } = useDataStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();
const showsplash = ref(true);
const checked = ref(null);
const currentInput = ref(0);

const showPin = ref(true);
const showPin1 = ref(true);
const successModal = ref(false);
const indemCheck = ref(false);
const pdfSrc = ref("");
const preview = ref(false);
const isRegistered = ref(null);
const submittingEmail = ref(false);
const submitEmail = async () => {
  try {
    submittingEmail.value = true;
    const res = await mutate({
      endpoint: "SaveRegisteringAccountEmail",
      data: {
        input: {
          email: args.email,
        },
      },
      service: "AUTH",
    });
    if (res.success) {
      currentInput.value = 1;
    } else {
      const data = Object.values(res.data)[0];

      console.log(data);

      if (data) {
        Object.keys(data).forEach(function (key) {
          if (data[key].length) {
            errorRules[key] = data[key][0];
            toast.error(data[key][0]);
          }
        });
      } else {
        toast.error(res.message);
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    submittingEmail.value = false;
  }
};
const handleBusinessType = (e) => {
  businessType.value = e;
  setTimeout(() => {
    currentInput.value++;
  }, 2000);
};
const handleBusinessRole = (e) => {
  companyRoleOthers.value = false;
  companyRole.value = e;
  setTimeout(() => {
    currentInput.value = 6;
  }, 2000);
};
const businessTypes = ref([
  {
    name: "Sole Proprietorship",
    value: "SOLE_PROPIETORSHIP",
  },
  {
    name: "Limited Liability Company (LLC)",
    value: "LIMITED_LIABILITY_COMPANY",
  },
  {
    name: "Public Limited Company (PLC)",
    value: "PUBLIC_LIMITED_COMPANY",
  },
  {
    name: "Non-Governmental Organisation (NGO)",
    value: "NON_GOVERNMENTAL_ORGANISATION",
  },
  {
    name: "Government Parastatal",
    value: "GOVERNMENTAL_PARASTATAL",
  },
]);
const roles = ref([
  {
    name: "Business Owner",
    value: "Business_owner",
  },

  {
    name: "Account Officer",
    value: "Account_officer",
  },
  {
    name: "HR Personnel",
    value: "HR_personnel",
  },
]);
const businessType = ref("");
const companyRole = ref("");
const companyRoleOthers = ref(false);
const setIsRegistered = (e) => {
  isRegistered.value = e;
  setTimeout(() => {
    currentInput.value++;
  }, 2000);
};
// function previewPDF(base64Data) {
//   const byteCharacters = atob(base64Data);
//   const byteArrays = [];
//   for (let offset = 0; offset < byteCharacters.length; offset += 512) {
//     const slice = byteCharacters.slice(offset, offset + 512);
//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }
//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }
//   const blob = new Blob(byteArrays, { type: "application/pdf" });
//   const url = URL.createObjectURL(blob);

//   preview.value = true;
//   pdfSrc.value = url;
// }

const loading = reactive({
  btnGetstarted: false,
  btnOtp: false,
  confirm: false,
});
// const loadingProgress = ref(false);

// //Query Indemnity contract when done
// async function queryIndemnityContract() {
//   loadingProgress.value = true;

//   try {
//     await query({
//       endpoint: "LoanContract",
//       payload: {
//         loanId: route.query.id,
//       },
//       service: "LOAN",
//       storeKey: "loanContract",
//     });
//     previewPDF(contract.value.base64);
//   } catch (e) {
//     console.log(e);
//   } finally {
//     loadingProgress.value = false;
//   }
// }

const accountType = ref(window.localStorage.getItem("registrationType"));

const args = reactive({
  registrationType:
    accountType.value === "COMPANY"
      ? "COMPANY"
      : accountType.value === "PERSONAL"
      ? "PERSONAL"
      : "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  companySize: "1-10",
  pin: "",
  confirmPin: "",
  referralCode: "",
});

//// error rules for input fields ////
const errorRules = reactive({
  firstName: false,
  lastName: false,
  email: false,
  phoneNumber: false,
  company: false,
  pin: false,
  confirmPin: false,
  referralCode: false,
  otpCode: false,
});

const otpCode = ref("");
// const display = ref(window.localStorage.getItem("display"));
const display = ref('account-type');
const showPasscode = ref(false);

watch(display, (val) => {
  window.localStorage.setItem("display", val);
});

watch(accountType, (val) => {
  args.registrationType = val;
  window.localStorage.setItem("registrationType", val);
});

onMounted(() => {
  ///Init the value to the display on load to show the right form///
  display.value === null ? (display.value = "account-type") : display.value;
  // accountType.value === null
  //   ? (accountType.value = "PERSONAL")
  //   : accountType.value;

  if (route.query.display) {
    // display.value = route.query.display;
  } else if (route.query.ref) {
    args.referralCode = route.query.ref;
  }
  // display.value = "otp-verify";
  console.log(display.value);
});

function selectregistrationType(payload) {
  if (payload) {
    accountType.value = payload;
    checked.value = true;
  } else {
    accountType.value = null;
    checked.value = null;
  }
}

function validateForm() {
  const number = processNumber(args.phoneNumber);

  if (args.firstName.trim() === "") {
    errorRules.firstName = "Please fill in your first name";
    return false;
  } else if (args.lastName.trim() === "") {
    errorRules.lastName = "Please fill in your last name";
    return false;
  } else if (args.email.trim() === "") {
    errorRules.email = "Please fill in your email address";
    return false;
  } else if (!validateEmail(args.email.trim())) {
    errorRules.email = "Please enter a valid email address";
  } else if (args.phoneNumber.trim() === "") {
    errorRules.phoneNumber = "Please fill in your phone number";
    return false;
  } else if (!validatePhone(args.phoneNumber.trim())) {
    errorRules.phoneNumber = "Please fill in a valid phone number";
  } else if (
    isNaN(args.phoneNumber.trim()) ||
    processNumber(args.phoneNumber).length > 13
  ) {
    errorRules.phoneNumber = "Please fill in a valid phone number";
    return false;
  } else if (
    args.companyName.trim() === "" &&
    args.registrationType === "COMPANY"
  ) {
    errorRules.company = "Please fill in your company";
    return false;
  } else if (args.pin.trim() === "") {
    errorRules.pin = "Please set a pin";
    return false;
  } else if (args.confirmPin.trim() === "") {
    errorRules.confirmPin = "Please confirm your pin";
    return false;
  } else if (args.pin !== args.confirmPin) {
    errorRules.pin = "Pin does not match. Please check and put a matching pin";
    return false;
  } else {
    //Reset Error
    Object.keys(errorRules).forEach((key) => {
      errorRules[key] = false;
    });

    return true;
  }
}
async function switchToPasscode() {
  if (validateForm()) {
    showPasscode.value = true;
  }
}

async function logRegistration(arg, title) {
  try {
    await mutate({
      endpoint: "LogRegister",
      data: { input: arg, title },
      service: "AUTH",
    });
  } catch (e) {
    console.log(e);
  }
}
const logPayload = ref({});
function sixChar(inp) {
  return inp.slice(0, 6);
}
async function submit() {
  console.log("result", validateForm());
  if (validateForm()) {
    window.localStorage.setItem("userDetails", JSON.stringify(args));
    // const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
    const payload = {
      registrationType: args.registrationType,
      lastName: args.lastName,
      firstName: args.firstName,
      email: args.email.trim().toLocaleLowerCase(),
      phoneNumber: processNumber(args.phoneNumber),
      companyName: args.companyName,
      companySize: args.companySize,
      pin: sixChar(String(args.pin)),
      confirmPin: args.confirmPin,
      referralCode: args.referralCode ? args.referralCode : null,
      businnesType: businessType.value,
      adminCompanyRole: companyRole.value,

      isRegistered: isRegistered.value,
    };
    if (!companyRole.value) delete payload.adminCompanyRole;
    if (!businessType.value) delete payload.businnesType;
    if (!isRegistered.value) delete payload.isRegistered;
    console.log("payload", {
      ...payload,
      isRegistered: isRegistered.value,
      businessType: businessType.value,
      companyRole: companyRole.value,
    });
    logPayload.value = payload;

    try {
      loading.btnGetstarted = true;
      let res = await mutate({
        endpoint: "LiteRegisterCompany",
        data: { input: payload },
        service: "AUTH",
      });

      log(res);
      if (res.success) {
        toast.success("Signup successful, Please check email for otp code");

        await sendOTP();

        // display.value = "otp-verify";
        loading.btnGetstarted = false;
      } else {
        loading.btnGetstarted = false;
        const data = Object.values(res.data)[0];

        console.log(data);

        if (data) {
          Object.keys(data).forEach(function (key) {
            if (data[key].length) {
              errorRules[key] = data[key][0];
              toast.error(data[key][0]);
            }
          });
        } else {
          toast.error(res.message);
        }
        await logRegistration(payload, "Attempted Registration");
      }
    } catch (e) {
      loading.btnGetstarted = false;
      toast.error(e.message);
      await logRegistration(payload, "Attempted Registration");
      console.log(e);
    }
  } else {
  }
}
const otpTimer = ref(null);

function startOTPTimer() {
  // Clear the existing timer, if any
  clearTimeout(otpTimer.value);

  // Set the timer for 2-3 minutes (in milliseconds)
  otpTimer.value = setTimeout(async function () {
    console.log("OTP not entered within the time limit.");

    await logRegistration(logPayload.value, "OTP not inputted after 2 minutes");
    // Call your function here to handle the case when OTP isn't entered
    // For example, you can show an error message or take some other action.
  }, 2 * 60 * 1000); // 2 minutes (change to 3 minutes by replacing 2 with 3)
}

async function sendOTP() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));

  //validate form field

  const payload = {
    channel: "EMAIL",
    // token_length: 4,
    value: userDetails.email,
    type: "SIGNUP",
    // phoneNumber: processNumber(userDetails.phoneNumber),
    // firstName: userDetails.firstName,
  };

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });
    if (res.success) {
      //Store form details and reference in localstorage
      // window.localStorage.setItem("userDetails", JSON.stringify(args));
      window.localStorage.setItem("otpReference", res.data.reference);
      //send user to OTP form
      console.log("succesfully", res);
      currentInput.value = 7;
      startOTPTimer();
    } else {
    }
  } catch (e) {
    console.log(e);
  }
}

async function confirmRegistration() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const confirmUserDetails = JSON.parse(
    window.localStorage.getItem("confirmUserDetails")
  );

  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    clearTimeout(otpTimer.value);
    loading.confirm = true;
    // console.log(otpCode.value);

    let payload;
    if (route.query.display) {
      payload = {
        reference: window.localStorage.getItem("otpReference"),
        code: otpCode.value,
        value: confirmUserDetails.value,
      };
    } else {
      payload = {
        reference: window.localStorage.getItem("otpReference"),
        code: otpCode.value,
        value: userDetails.email,
      };
    }

    try {
      let res = await mutate({
        endpoint: "ConfirmRegistration",
        data: { input: payload },
        service: "AUTH",
      });
      log(res);
      if (res.success) {
        console.log("res is", res);
        // Set form back to register
        // display.value = "account-type";
        //Clear error state
        errorRules.otpCode = false;
        loading.confirm = false;
        successModal.value = true;

        setTimeout(async () => {
          successModal.value = false;
          // toast.success(res.message);

          window.localStorage.setItem("token", res.token);
          window.localStorage.setItem("refreshToken", res.refreshToken);
          window.localStorage.setItem("privilege", res.privileges);
          window.localStorage.setItem("companyId", res.data.company._id);

          //Turn on Tour
          localStorage.setItem("tour", "true");

          useDataStore().$patch({
            userPrivileges: res.privileges,
            isLoggedIn: true,
          });
          // display.value = "account-type";
          router.push("admin/dashboard");
          await queryCompany();
        }, 4000);
      } else {
        errorRules.otpCode = res.message;
        toast.error(res.message);
        loading.confirm = false;
      }
    } catch (e) {
      log(e);
      loading.confirm = false;
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    }
  }
}

async function resendOTP() {
  const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const confirmUserDetails = JSON.parse(
    window.localStorage.getItem("confirmUserDetails")
  );

  // log(args);
  // log("user details", userDetails);

  let payload;

  if (route.query.display) {
    payload = {
      channel: confirmUserDetails.channel,
      value: confirmUserDetails.value,
      type: "SIGNUP",
    };
  } else {
    payload = {
      channel: "EMAIL",
      value: userDetails.email,
      type: "SIGNUP",
    };
  }

  try {
    loading.btnOtp = true;
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });

    if (res.success) {
      loading.btnOtp = false;
      toast.success("OTP sent successful. Please check email");
      window.localStorage.setItem("otpReference", res.data.reference);
    }
  } catch (e) {
    loading.btnOtp = false;
  }
}

//Update OTP Input Functions
function checkPin(otp_input) {
  args.pin = otp_input;
}

function checkConfirmPin(otp_input) {
  args.confirmPin = otp_input;
}

function checkOTP(otp_input) {
  otpCode.value = otp_input;
}

async function queryCompany() {
  await query({
    endpoint: "FetchCompaniesByAdminId",
    service: "SETTINGS",
    storeKey: "companyDetails",
  });
}
</script>

<style scoped>
[type="checkbox"]:checked {
  border-color: transparent;
  border: 2px solid #11453b;
  background-color: white;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
